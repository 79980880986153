<!--
* @Author: tianzl
* @Date: 2023-12-05 13:46:28
* @LastEditors: tianzl
* @LastEditTime: 2023-12-05 13:46:28
* @Description: 新建线索
 -->
 <template>
  <div class="create-clue">
    <van-form ref="createForm" input-align="right" error-message-align="right"  @submit.native.prevent>
      <van-collapse v-model="activeNames" style="padding: 16px;">
        <van-collapse-item class="form-collapse-item" title="线索信息" name="1">
          <!-- 手机号码 -->
          <van-field
            v-model.trim="formData.userMobile"
            required
            type="number"
            label="联系电话"
            placeholder="请输入联系电话"
            :required="userMobileCheck.some((item) => item.required)"
            :rules="userMobileCheck"
            :formatter="(value)=> value.replace(/\s+/g, '')"
          />
          <!-- 微信 -->
          <van-field
            label-class="van-field-label"
            label="客户微信"
            placeholder="请选择一个微信好友"
            :value="formData.wechatInfo.name"
            readonly>
            <template #button>
              <div style="display: flex;">
                <div v-if="!formData.wechatInfo.name" class="btn-item active commonActive" @click="openAddCustomer">添加</div>
                <div class="btn-item active commonActive" @click="selectExternalContact">绑定</div>
              </div>
            </template>
          </van-field>
          <!-- 客户姓名 -->
          <van-field
            v-model.trim="formData.userName"
            required
            label="客户姓名"
            maxlength="20"
            placeholder="请输入客户姓名"
            :rules="[{required: true, trigger: 'onBlur'}]"
          />
          <!-- 来源渠道 -->
          <van-field
            label="来源渠道"
            required
            placeholder="请选择来源渠道"
            :value="formData.customerSource.displayName"
            right-icon="arrow"
            type="textarea"
            autosize
            rows="1"
            readonly
            :rules="[{required: true, trigger: 'onBlur'}]"
            @click="onClueSourceSelect"
          />
          <!-- 客户性别 -->
          <div class="common-cell">
            <span class="common-cell-label">客户性别</span>
            <div class="common-cell-value flex-wrap-clue">
              <div v-for="(item,index) in genderList" :key="index" class="btn-item" :class="{ commonActive: item.dictCode === formData.userSex}" @click="formData.userSex = item.dictCode">{{item.dictName}}</div>
            </div>
          </div>
          <!-- 客户类型 -->
          <div class="common-cell">
            <span class="common-cell-label">客户类型</span>
            <div class="common-cell-value flex-wrap-clue">
              <div v-for="(item,index) in customerTypeList" :key="index" class="btn-item" :class="{ commonActive: item.dictCode === formData.userType}" @click="formData.userType = item.dictCode">{{item.dictName}}</div>
            </div>
          </div>
          <!-- 公司名称 -->
          <van-field
            v-if="formData.userType === 2"
            v-model.trim="formData.companyName"
            label="公司名称"
            maxlength="50"
            placeholder="请输入"
          />
          <van-cell>
            <template #default>
              <div class="complete" @click="improveUser">完善客户信息</div>
            </template>
          </van-cell>
        </van-collapse-item>
        <!-- <van-collapse-item class="form-collapse-item" title="首次跟进记录" name="2">
          <FollowInfo ref="followInfo" :from="'create'" @changeTypeCode="changeTypeCode"></FollowInfo>
        </van-collapse-item> -->
      </van-collapse>
      <div class="common-footer">
        <van-button class="btn back" native-type="button" @click.stop="goBack">返回</van-button>
        <van-button class="btn submit" type="submit" @click="onSubmit">{{ btnTxt }}</van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import BtnVoiceInput from '@/components/btn-voice-input'
import { mapGetters } from 'vuex'
import { validate } from '@/utils/validate'
import selectClueSource from '@/components/select-clue-source'
import selectCar from '@/components/select-car'
import clueServices from '@/services/clueServices'
import baseDataServices from '@/services/baseDataServices'
import loading from '@/utils/loading'
import store from '@/store'
import { dateFormat } from '@/utils'

export default {
  name: 'createClue',
  filters: {
    formatCarInfo(info) {
      const { seriesName, modelName } = info
      let display = ''
      seriesName && (display += ` ${seriesName}`)
      modelName && (display += ` ${modelName}`)
      return Object.keys(info).length === 0
        ? null
        : display
    },
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (from.path != '/edit-clue') {
        store.commit('app/setTemplateInfo', {})
      }
    })
  },
  components: {
    BtnVoiceInput
  },
  data() {
    return {
      validate,
      activeNames: ['1', '2'],
      formData: {
        userMobile: '',
        wechatInfo: {}, // 微信信息
        userName: '',
        customerSource: {}, // 来源渠道
        userSex: '',
        userType: '',
        companyName: '',
      },
      carInfoErrMsg: '',
      genderList:[
        { dictName: '男', dictCode: 1 },
        { dictName: '女', dictCode: 2 },
      ],
      customerTypeList: [
        { dictName: '个人购车', dictCode: 1 },
        { dictName: '公司购车', dictCode: 2 },
      ],
      judgeExists: false,
      btnTxt: '提交'
    }
  },
  computed: {
    ...mapGetters(['shops', 'dictHash','userInfo', 'templateClueInfo']),
    // 客户手机号验证
    userMobileCheck() {
      // 跟进方式-展厅接待可不填手机号
      const { userMobile, wechatInfo } = this.formData
      const rules = []
      // 如果有手机号则验证
      if (userMobile) {
        rules.push(validate.mustPhone)
      }
      if (!wechatInfo?.externalUserid) {
        rules.push({ required: true, trigger: 'onChange' })
      }
      return rules
    },
  },
  watch: {
    'formData.userMobile'(value){
      if (/^1[3456789]\d{9}$/.test(value)){
        this.baseClueInfoFilled('tel')
      }
    },
  },
  mounted() {
    const { userName, userMobile, userSex, userType, companyName, wechatInfo={}, userWeChatName, customerSource={} } = this.templateClueInfo
    Object.assign(this.formData, { userName, userMobile, userSex, userType, companyName, userWeChatName, wechatInfo, customerSource })
  },
  methods: {
    // 通过电话号码判重
    async baseClueInfoFilled(type) {
      if (this.judgeExists) return false
      this.judgeExists = true
      try {
        loading.showLoading('线索验证中')
        const { wechatInfo: { externalUserid:userWechat }, userMobile } = this.formData
        const record = await clueServices.clueExist({ userWechat, userMobile, dealerId: this.userInfo.dealers[0]?.id })
        this.judgeExists = false
        loading.hideLoading()
        this.handleLink(record, type)
      } catch (error) {
        loading.hideLoading()
        this.judgeExists = false
        this.$toast.fail(error.message)
      }
    },
    
    // 校验结果处理
    handleLink(data, type) {
      const { followUserId, followUserName, id, isRecycle, followUserRoleCode } = data
      if (!id) return false
      if (isRecycle == 0) { // 未被回收的逻辑
        // 无跟进人
        if (!followUserId) {
          this.$dialog.confirm({
            title: '提示',
            message: '该线索还未分配跟进人，是否领取 ？',
            confirmButtonColor: '#B9921A',
            confirmButtonText: '领取',
          }).then(async() => {
            const result = await clueServices.receiveClue({ id })
            if (result) {
              this.$toast('领取成功')
              setTimeout(() => {
                this.goFollow(id)
              }, 1000)
            }
          }).finally(() => {
            type === 'tel' ? this.formData.userMobile = '' : this.formData.wechatInfo = {}
          })
        }
        // 跟进人是自己
        if (followUserId === this.userInfo.id) {
          this.$dialog.confirm({
            title: '提示',
            message: '您正在跟进该客户，无需重复创建，是否去填写跟进记录？',
            confirmButtonColor: '#B9921A',
            confirmButtonText: '去填写',
          }).then(() => {
            this.goFollow(id)
          }).finally(() => {
            type === 'tel' ? this.formData.userMobile = '' : this.formData.wechatInfo = {}
          })
        }
        // 跟进人是他人
        if (followUserId && followUserId !== this.userInfo.id) {
          this.$dialog.confirm({
            title: '提示',
            message: `【${followUserName}】正在跟进该客户，不允许重复创建，您是否去填写跟进记录？`,
            confirmButtonColor: '#B9921A',
            confirmButtonText: '去填写',
          }).then(() => {
            // this.goFollow(data.id)
            this.$router.push({
              path: '/clue-remark',
              query: {
                id,
              }
            })
          }).finally(() => {
            type === 'tel' ? this.formData.userMobile = '' : this.formData.wechatInfo = {}
          })
        }
      } else if (isRecycle == 1) { // 回收的逻辑
        if (followUserId === this.userInfo.id) {
          this.$dialog.confirm({
            title: '提示',
            message: '由于您未及时跟进，该线索已被回收至门店公共池，不允许重复创建',
            showCancelButton: false,
            confirmButtonColor: '#B9921A',
            confirmButtonText: '确认',
          }).then(() => {
          }).finally(() => {
            type === 'tel' ? this.formData.userMobile = '' : this.formData.wechatInfo = {}
          })
        }
        if (followUserId && followUserId !== this.userInfo.id) {
          const roleLogos = this.userInfo.roleLogos || []
          if (roleLogos.includes(followUserRoleCode)) {
            this.$dialog.confirm({
              title: '提示',
              message: '店内公共池已存在同号码线索，不允许重复创建，是否直接领取？',
              confirmButtonColor: '#B9921A',
              confirmButtonText: '领取',
            }).then(async() => {
              const result = await clueServices.receiveRecycle({ id })
              if (result) {
                this.$toast('领取成功')
                setTimeout(() => {
                  this.goFollow(id)
                }, 1000)
              }
            }).finally(() => {
              type === 'tel' ? this.formData.userMobile = '' : this.formData.wechatInfo = {}
            })
          } else {
            this.$dialog.confirm({
              title: '提示',
              message: `店内公共池已存在同号码线索，不允许重复创建；由于您无原跟进同角色权限（${this.getDictNameByCode(1014, followUserRoleCode)}），无法直接领取`,
              showCancelButton: false,
              confirmButtonColor: '#B9921A',
              confirmButtonText: '确认',
            }).then(() => {
            }).finally(() => {
              type === 'tel' ? this.formData.userMobile = '' : this.formData.wechatInfo = {}
            })
          }
        }
      }
    },
    // 选择微信联系人
    async selectExternalContact() {
      this.formData.wechatInfo = {}
      window.wx.invoke('selectExternalContact',{ filterType: 0 },
        async res => {
          if (res.err_msg != 'selectExternalContact:ok') return
          const { userIds } = res
          if (!userIds || userIds.length < 1) {
            return
          }
          if (userIds.length > 1) {
            return this.$toast('请仅选择一个外部联系人进行绑定')
          }
          const [userUuid] = userIds
          const crmCustomer = await baseDataServices.getExternalDetail({ externalUserId : userUuid })
          if (!crmCustomer.externalUserid) {
            this.$toast.fail('无效联系人，请重新选择')
            return
          }
          this.formData.wechatInfo = crmCustomer
          this.baseClueInfoFilled('wx')
        }
      )
    },
    // 添加微信好友
    openAddCustomer() {
      window.wx.invoke('navigateToAddCustomer',{},
        function(res) {
        })
    },
    // 选择来源渠道
    onClueSourceSelect() {
      const dealerVisible = this.$store.getters.userInfo.type === 1 ? 1 : null
      selectClueSource({ noStrictly:false, dealerVisible }).then(data => {
        const res = data.value
        this.formData.customerSource.data = res
        this.$set(this.formData.customerSource, 'displayName', res.map(({ text }) => text).filter(val => val).join(' '))
      }).catch(err => console.log(err))
    },
    // 选择意向车型
    selectCarModel() {
      const { carInfo: initCarInfo } = this.formData
      selectCar({ initCarInfo })
        .then(carInfo => {
          if (typeof carInfo.modelCode === 'undefined') {
            this.$toast('请选择正确的车系车型')
            return
          }
          this.formData.carInfo = carInfo
          this.$refs.carInfo.validate()
        })
        .catch(() => { })
    },
    // 取消
    goBack() {
      this.$router.go(-1)
    },
    // 完善客户信息
    improveUser() {
      const {
        userMobile,
        wechatInfo,
        userName,
        userSex,
        userType,
        companyName,
        customerSource
      } = this.formData
      // const followRequest = this.$refs.followInfo.exportData()
      // const {
      //   carInfo: {
      //     seriesCode,
      //     seriesName,
      //     modelCode,
      //     modelName,
      //   },
      // } = followRequest
      const params = {
        customerSource,
        userMobile,
        wechatInfo,
        userName,
        userSex,
        userType,
        companyName,
        // seriesCode,
        // seriesName,
        // modelCode,
        // modelName,
      }
      store.commit('app/setTemplateInfo', Object.assign(this.templateClueInfo, params))
      this.$router.push({
        name:'editClue',
        query:{
          id: '',
          operate: 'create',
          templateClueInfo: JSON.stringify(params)
        }
      })
    },
    // 保存
    async onSubmit() {
      // let validateList = []
      // validateList.push(this.$refs.followInfo.validateForm())
      // validateList.push(this.$refs.followInfo.$refs.leadLevel?.validateLeadInfo())
      // const validation = Promise.all(validateList)
      // const validationRes = await validation.catch(err => {
      //   this.$toast('请完成必填字段的填写后再提交')
      // })
      // if (!validationRes) return
      // const followRequest = this.$refs.followInfo.exportData()
      const {
        userMobile,
        wechatInfo: {
          externalUserid: userWeChat,
          name: userWeChatName,
        },
        userName,
        customerSource: { // 来源渠道
          data: permissionList,
        },
        userSex,
        userType,
        companyName,
      } = this.formData
      // const {
      //   carInfo: {
      //     seriesCode,
      //     seriesName,
      //     modelCode,
      //     modelName,
      //   },
      //   storeOfTime
      // } = followRequest
      let permission = {}
      permissionList.forEach((item, index) => {
        switch (index) {
          case 0:
            permission['channelOneId'] = item.id
            permission['channelOne'] = item.text
            break
          case 1:
            permission['channelTwoId'] = item.id
            permission['channelTwo'] = item.text
            break
          case 2:
            permission['channelThreeId'] = item.id
            permission['channelThree'] = item.text
            break
          case 3:
            permission['channelFourId'] = item.id
            permission['channelFour'] = item.text
            break
          case 4:
            permission['channelFiveId'] = item.id
            permission['channelFive'] = item.text
            break
        }
      })
      const today = dateFormat(new Date(),'YYYY-MM-DD')
      const params = {
        ...this.templateClueInfo,
        userMobile,
        userWeChat,
        userWeChatName,
        userName,
        ...permission, // 渠道来源
        userSex,
        userType,
        companyName,
        // seriesCode,
        // seriesName,
        // modelCode,
        // modelName,
        // followRequest: { 
        // ...followRequest,
        // storeOfTime: storeOfTime?`${today} ${storeOfTime}:00` :'' 
        // }
      }
      delete params.carInfo
      clueServices.creatClue(params).then(res => {
        this.$toast.success('创建成功')
        setTimeout(() => {
          this.$router.go(-1)
        }, 2000)
      }).catch()
    }
  },

}
</script>
<style scoped lang="less">
@import "../../clue/style/commonless.less";
@create-main-color: #3476FE;
.create-clue{
  padding-bottom: 96px;
  /deep/ .common-cell{
    position: relative;
    display: flex;
    font-size: 14px;
    padding: 10px 16px;
    &.required::before{
      position: absolute;
      top: 16px;
      left: 8px;
      color: #EED484;
      font-size: 15px;
      content: '*'
    }
    &::after{
      content: '';
      position: absolute;
      box-sizing: border-box;
      pointer-events: none;
      right: 16px;
      bottom: 0;
      left: 16px;
      border-bottom: 1px solid #ebedf0;
      -webkit-transform: scaleY(0.5);
      transform: scaleY(0.5);
    }
    .common-cell-label{
      color: @black;
      width: 6.2em;
      margin-right: 12px;
      line-height: 32px;
    }
    .no-list{
      line-height: 32px;
    }
    .common-cell-value{
      flex: 1;
    }
  }
  /deep/ .flex-wrap-clue{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -10px;
    justify-content: flex-end;
    .btn-item{
      overflow: hidden;
      margin-bottom: 10px;
      &:not(:last-child){
        margin-right: 10px;
      }
    }
  }
  /deep/ .btn-item{
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 8px;
    height: 24px;
    color: @black;
    background-color: #F5F5F5;
    border-radius: 4px;
    font-size: 12px;
    &.active {
      // border: 1px solid @yellow;
      // color: @yellow-text;
    }
    &.commonActive{
      color: #B9921A;
      background: rgba(238,212,132,0.45);
      &:first-child{
        margin-right: 4px;
      }
    }
  }
  /deep/ .complete{
    display: flex;
    align-items: center;
    justify-content: center;
    color: @yellow-text;
    cursor: pointer;
  }
}
</style>

